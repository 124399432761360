import { trackEvent } from "@leafly-com/web-utils";
import { NextRouter } from "next/router";

import { Query } from "custom-types/Query";
import { StrainFilters, StrainFilterValue } from "custom-types/StrainList";
import { StrainTag, StrainTags } from "custom-types/StrainTags";

export const FILTER_EVENT_MAP: { [key: string]: string } = {
  conditions_included: "condition",
  effects_excluded: "avoid",
  effects_included: "effect",
  flavors_included: "flavor",
  helps_with: "helps with",
  negatives_excluded: "avoid",
  online_fulfillment_enabled: "availability",
  phenotype: "category",
  strain_category: "category",
  strain_top_terp: "terpene",
  symptoms_included: "condition",
  thc: "thc",
  thc_ranges: "thc",
  top_flavors_included: "flavor",
  top_strain_effects_included: "effect",
  top_strain_negatives_excluded: "avoid",
};

export const thcLevels = [
  {
    displayName: "No THC",
    filterDisplayName: "THC:None",
    highlightedDisplay: "0%",
    slug: "*-0.0",
  },
  {
    displayName: "Low",
    filterDisplayName: "THC:Low",
    highlightedDisplay: "1-10%",
    slug: "1.0-10.0",
  },
  {
    displayName: "Medium",
    filterDisplayName: "THC:Medium",
    highlightedDisplay: "10-20%",
    slug: "10.0-20.0",
  },
  {
    displayName: "High",
    filterDisplayName: "THC:High",
    highlightedDisplay: ">20%",
    slug: "20.0-*",
  },
];

export const onFilterChange = (
  filter: string,
  slug: string,
  isSelected: boolean,
  toggleFilter: (newQuery: Query) => void,
): void => {
  trackEvent(
    "Strains Filter",
    "Click",
    `${isSelected ? "Remove" : "Add"} ${FILTER_EVENT_MAP[filter]} ${slug}`,
  );
  toggleFilter({ [filter]: slug });
};

export const onClearFilter = (
  clearAllFilters: (keepKeys: string[]) => void,
): void => {
  trackEvent("Strains Filter", "Click", "Clear All");
  clearAllFilters(["sort"]);
};

export const isFilterSelected = (
  filter: string,
  query: { [key: string]: string | string[] | undefined },
  slug: string,
): boolean => {
  const filterValue = query[filter];
  return filterValue
    ? typeof filterValue === "string"
      ? filterValue === slug
      : Array.isArray(filterValue)
        ? filterValue.indexOf(slug as never) > -1
        : false
    : false;
};

export const strainTagsToFilters = (tags: StrainTags): StrainFilters => {
  const symptomsWithFilter = addFilterName("symptoms_included", tags.symptoms);
  const conditionsWithFilters = addFilterName(
    "conditions_included",
    tags.conditions,
  );
  const conditionsSymptoms = combineAndSortFilter(
    symptomsWithFilter,
    conditionsWithFilters,
  );

  const effectsWithFilter = addFilterName("effects_excluded", tags.effects);

  const negativesWithFilters = addFilterName(
    "negatives_excluded",
    tags.negatives,
  );

  const effectsNegatives = combineAndSortFilter(
    effectsWithFilter,
    negativesWithFilters,
  );

  return [
    {
      displayName: "Strain types",
      filterNames: ["phenotype"],
      values: tags.categories,
    },
    {
      displayName: "THC level",
      filterNames: ["thc_ranges"],
      values: thcLevels,
    },
    {
      displayName: "Effects",
      filterNames: ["effects_included"],
      values: tags.effects,
    },
    {
      displayName: "Helps with",
      filterNames: ["symptoms_included", "conditions_included"],
      values: conditionsSymptoms,
    },
    {
      displayName: "Effects to avoid",
      filterNames: ["effects_excluded", "negatives_excluded"],
      values: effectsNegatives,
    },
    {
      displayName: "Terpenes",
      filterNames: ["strain_top_terp"],
      values: tags.terpenes,
    },
    {
      displayName: "Flavors",
      filterNames: ["flavors_included"],
      values: tags.flavors,
    },
    {
      displayName: "Available nearby",
      filterNames: ["online_fulfillment_enabled"],
      values: [
        {
          displayName: "Available nearby for online ordering",
          slug: "true",
        },
      ],
    },
  ];
};

export const addFilterName = (
  filterName: string,
  filterObject?: StrainTag[],
): StrainFilterValue[] =>
  filterObject?.map((obj: StrainTag) => ({
    ...obj,
    filterName,
  })) || [];

export const combineAndSortFilter = (
  arr1: StrainFilterValue[],
  arr2: StrainFilterValue[],
): StrainFilterValue[] =>
  [...arr1, ...arr2].sort((a, b) => a.slug.localeCompare(b.slug));

export const pushPage = (router: NextRouter, page: number): void => {
  router.push(
    {
      query: { ...router.query, page: page },
    },
    undefined,
    { scroll: true, shallow: true },
  );
};

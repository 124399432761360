import React, { useRef, useState } from "react";
import { simulateClick } from "@leafly-com/web-utils";
import classNames from "classnames";

import useClickAwayListener from "hooks/useClickAwayListener";

import SolidArrowIcon from "components/Icons/arrows/SolidArrowIcon";

/**
 * Dropdown menus are typically used to change the state of a particular component
 * (e.g. switching between Top-rated and Nearby strains in a carousel).
 * Dropdown menus are occasionally used as sub-navigation within a site section
 * when there are too many sub-pages to allow for tab navigation.
 */

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  className?: string;
  icon?: boolean;
  selectedItem: string | JSX.Element;
  onToggleDropdown?: (isOpen: boolean) => void;
  disabled?: boolean;
  trackClickEvent?: () => void;
};

const Dropdown: React.FC<Props> = ({
  children,
  className,
  icon = true,
  selectedItem,
  onToggleDropdown,
  disabled = false,
  trackClickEvent,
}) => {
  const [isOpen, setOpen] = useState(false);
  const dropdownEl = useRef(null);

  const toggleDropdown = () => {
    if (disabled) {
      return;
    }

    if (onToggleDropdown) {
      onToggleDropdown(!isOpen);
    }

    trackClickEvent && trackClickEvent();

    setOpen(!isOpen);
  };

  const classes = classNames(
    "dropdown__container",
    { "dropdown--open": isOpen },
    { "flex space-between": icon },
    { "dropdown--disabled cursor-not-allowed": disabled },
    className,
  );

  const handleClickAway = () => {
    if (onToggleDropdown) {
      onToggleDropdown(false);
    }

    setOpen(false);
  };

  const wrapperRef = useClickAwayListener(handleClickAway, isOpen);
  return (
    <div
      className={classes}
      tabIndex={disabled ? -1 : 0}
      role="button"
      aria-label="toggle dropdown"
      data-testid="dropdown"
      onKeyDown={!disabled ? simulateClick : undefined}
      onClick={toggleDropdown}
      ref={dropdownEl}
      aria-disabled={disabled}
    >
      {selectedItem}
      {icon && !disabled && <SolidArrowIcon />}
      {isOpen && !disabled && (
        <div ref={wrapperRef} className="dropdown__menu">
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
